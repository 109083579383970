<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='month'>
        <a-month-picker v-model="form.month" format="YYYY-MM" :valueFormat="monthFormatType" />
      </a-form-model-item>
      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator' style="margin-top: 20px">
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
      </div>
      <s-table id="table" ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: 1500}' :data='loadData'>
        <span slot='sticky' slot-scope='text'>
          <template>
            {{ stickyList.find(item => item.id == text).name }}
          </template>
        </span>
        <span slot='status' slot-scope='text'>
          <template>
            <a-badge v-if='text == 0' dot status='warning' :text='"审核中"' />
            <a-badge v-if='text == 1' dot status='warning' :text='"审核中"' />
            <a-badge v-if='text == 2' dot status='success' :text='"已审核"' />
          </template>
        </span>
        <span slot='qrUrl' slot-scope='text'>
          <template>
            <img :src='text' width='180px'>
          </template>
        </span>
        <span slot='action' slot-scope='text, record'>
          <template>
            <a-space>
              <a v-if="record.status != 2" @click='initialDeliver3(record)'>审核</a>
              <a v-else @click='initialDeliver2(record)'>上传二维码</a>
              <a @click='initialDeliver1(record)'>生成活动</a>
              <a-popconfirm title='确定删除吗？' ok-text='确认' cancel-text='取消'
                            @confirm='handleDelete(record)'>
                <a href='#'>删除</a>
              </a-popconfirm>
            </a-space>
          </template>
        </span>
      </s-table>
    </div>

    <a-modal :title='deliver1.form.id ? "编辑活动" : "新增活动"' :destroyOnClose='true' :maskClosable='true'
            :visible='deliver1.visible' @cancel='()=>this.deliver1.visible=false'
            ok-text='确认' cancel-text='取消' @ok='handleDeliver1' :confirmLoading='deliver1.loading'>
      <a-form-model ref='form1' :model='deliver1.form' :rules='deliver1.rules' :labelCol='{ span: 8 }' :wrapperCol='{ span: 16 }'>
        <a-form-model-item label='活动名称' prop='title'>
          <a-input v-model='deliver1.form.title' />
        </a-form-model-item>
        <a-form-model-item label='活动类型' prop='sticky'>
          <a-select v-model="deliver1.form.sticky" placeholder="活动类型">
            <a-select-option v-for="item in stickyList" :key="item.id" :value="item.id">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- <a-form-model-item label='活动主描述' prop='activityMainDes'>
          <a-input v-model='deliver1.form.activityMainDes' />
        </a-form-model-item> -->
        <a-form-model-item label='活动城市' prop='activityCity'>
          <a-input v-model='deliver1.form.activityCity' />
        </a-form-model-item>
        <a-form-model-item label='负责人' prop='activityMasterMember'>
          <a-select show-search v-model='deliver1.form.activityMasterMember'
                    :default-active-first-option='false'
                    :filter-option='false'
                    :allowClear='true'
                    placeholder="请选择"
                    @change="handleChange1"
                    @search='handleSearch1'>
            <a-select-option v-for='item in  presidentMerchantList' :key='item.id'>
              {{ item.masterName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="活动开始时间" prop='startDate'>
          <a-date-picker :valueFormat="createFormatType" v-model="deliver1.form.startDate" showTime placeholder="选择日期时间"/>
        </a-form-model-item>
        <a-form-model-item label="活动结束时间" prop='endDate'>
          <a-date-picker :valueFormat="createFormatType" v-model="deliver1.form.endDate" showTime placeholder="选择日期时间"/>
        </a-form-model-item>
        <a-form-model-item label="二维码截止时间" prop='qrEndDate'>
          <a-date-picker :valueFormat="createFormatType" v-model="deliver1.form.qrEndDate" showTime placeholder="选择日期时间"/>
        </a-form-model-item>
        <a-form-model-item label='活动背景图' prop='cover'>
          <a-upload
            list-type='picture-card'
            :file-list="deliver1.form.cover ? [{
              uid: '-1',
              name: 'cover',
              status: 'done',
              url: deliver1.form.cover,
            }] : []"
            :customRequest='(event) => {handleUpload(event, "cover")}'
            :remove='() => deliver1.form.cover = ""'>
            <div v-if="!deliver1.form.cover">
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label='活动详细描述' prop='contentRichText'>
          <a-textarea v-model='deliver1.form.contentRichText' />
        </a-form-model-item>
        <a-form-model-item label='活动信息权限' prop='activityPermissionMember'>
          <a-select show-search v-model='deliver1.form.activityPermissionMember'
                    :default-active-first-option='false'
                    :filter-option='false'
                    :allowClear='true'
                    placeholder="请选择"
                    @change="handleChange2"
                    @search='handleSearch2'>
            <a-select-option v-for='item in merchantList' :key='item.id'>
              {{ item.masterName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal title='上传二维码' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver2.visible' @cancel='()=>this.deliver2.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver2' :confirmLoading='deliver2.loading'>
      <a-form-model ref='form2' :model='deliver2.form' :rules='deliver2.rules' :labelCol='{ span: 8 }' :wrapperCol='{ span: 16 }'>
        <a-form-model-item label='活动二维码' prop='qrUrl'>
          <a-upload
            list-type='picture-card'
            :file-list="deliver2.form.qrUrl ? [{
              uid: '-1',
              name: 'qrUrl',
              status: 'done',
              url: deliver2.form.qrUrl,
            }] : []"
            :customRequest='(event) => {handleUpload2(event, "qrUrl")}'
            :remove='() => deliver2.form.qrUrl = ""'>
            <div v-if="!deliver2.form.qrUrl">
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal :title='deliver3.form.id ? "编辑活动申请" : "新增活动申请"' :destroyOnClose='true' :maskClosable='true'
            :visible='deliver3.visible' @cancel='()=>this.deliver3.visible=false'
            ok-text='审核' cancel-text='取消' @ok='handleDeliver3' :confirmLoading='deliver3.loading'>
      <a-form-model ref='form3' :model='deliver3.form' :rules='deliver3.rules' :labelCol='{ span: 8 }' :wrapperCol='{ span: 16 }'>
        <a-form-model-item label='活动名称' prop='title'>
          <a-input v-model='deliver3.form.title' />
        </a-form-model-item>
        <a-form-model-item label='活动类型' prop='sticky'>
          <a-select v-model="deliver3.form.sticky" placeholder="活动类型">
            <a-select-option v-for="item in stickyList" :key="item.id" :value="item.id">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label='活动地址' prop='activityCity'>
          <a-input v-model='deliver3.form.activityCity' />
        </a-form-model-item>
        <a-form-model-item label='举办人' prop='activityMasterMember'>
          <a-input v-model='deliver3.form.activityMasterMember' />
        </a-form-model-item>
        <a-form-model-item label='所在大区负责人' prop='areaMember'>
          <a-input v-model='deliver3.form.areaMember' />
        </a-form-model-item>
        <a-form-model-item label="活动开始时间" prop='startTime'>
          <a-date-picker :valueFormat="createFormatType" v-model="deliver3.form.startTime" showTime placeholder="选择日期时间"/>
        </a-form-model-item>
        <a-form-model-item label="活动结束时间" prop='endTime'>
          <a-date-picker :valueFormat="createFormatType" v-model="deliver3.form.endTime" showTime placeholder="选择日期时间"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis, ChartCard } from '@/components'
import { queryMerchant } from '@/api/member'
import { putActivity, queryActivityApply, putActivityApply, deleteActivityApply } from '@/api/activity'
import moment from 'moment'
import { mapGetters } from 'vuex'
import client from '@/config/oss.config.js'

const columns = [
  {
    width: 150,
    title: '开始时间',
    dataIndex: 'startTime',
    scopedSlots: { customRender: 'startTime' }
  },
  {
    width: 150,
    title: '结束时间',
    dataIndex: 'endTime',
    scopedSlots: { customRender: 'endTime' }
  },
  {
    width: 150,
    title: '活动名称',
    dataIndex: 'title',
    scopedSlots: { customRender: 'title' }
  },
  {
    width: 150,
    title: '活动类型',
    dataIndex: 'sticky',
    scopedSlots: { customRender: 'sticky' }
  },
  {
    width: 150,
    title: '活动地址',
    dataIndex: 'activityCity',
    scopedSlots: { customRender: 'activityCity' }
  },
  {
    width: 150,
    title: '举办人',
    dataIndex: 'activityMasterMember',
    scopedSlots: { customRender: 'activityMasterMember' }
  },
  {
    width: 150,
    title: '品牌公司负责人',
    dataIndex: 'areaMember',
    scopedSlots: { customRender: 'areaMember' }
  },
  {
    width: 100,
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    width: 200,
    title: '二维码',
    dataIndex: 'qrUrl',
    scopedSlots: { customRender: 'qrUrl' }
  },
  {
    width: 250,
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

const monthFormatType = 'YYYY-MM';
const createFormatType = 'YYYY-MM-DD HH:mm:ss';

export default {
  name: 'ActivityApplyList',
  components: {
    STable, Ellipsis, ChartCard
  },
  data() {
    return {
      // 筛选表单
      monthFormatType,
      createFormatType,
      form: {
        month: moment().format(monthFormatType),
      },
      loading: false,
      dashboard: {}, //订单统计数据
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      queryParam: {},
      loadData: parameter => {
        return queryActivityApply(Object.assign(parameter, this.queryParam, this.form))
          .then(datum => {
            return datum
          })
      },
      merchantList: [],
      presidentMerchantList: [],
      deliver1: { 
        visible: false, 
        loading: false,
        form: {},
        rules: {          
          title: [{ required: true, message: '请输入活动名称', trigger: 'change' }],
          sticky: [{ required: true, message: '请选择活动类型', trigger: 'change' }],
          activityCity: [{ required: true, message: '请输入活动城市', trigger: 'change' }],
          activityMasterMember: [{ required: true, message: '请选择负责人', trigger: 'change' }],
          startDate: [{ required: true, message: '请选择', trigger: 'change' }],
          endDate: [{ required: true, message: '请选择', trigger: 'change' }],
          qrEndDate: [{ required: true, message: '请选择', trigger: 'change' }],
          cover: [{ required: true, message: '请选择', trigger: 'change' }],
          // activityPermissionMember: [{ required: true, message: '请选择活动信息权限', trigger: 'change' }],
        },
      },
      deliver2: { 
        visible: false, 
        loading: false,
        form: {},
        rules: {          
          qrUrl: [{ required: true, message: '请上传', trigger: 'change' }],
        },
      },
      deliver3: { 
        visible: false, 
        loading: false,
        form: {},
        rules: {          
          title: [{ required: true, message: '请输入活动名称', trigger: 'change' }],
          sticky: [{ required: true, message: '请选择活动类型', trigger: 'change' }],
          activityCity: [{ required: true, message: '请输入活动地址', trigger: 'change' }],
          activityMasterMember: [{ required: true, message: '请输入举办人', trigger: 'change' }],
          areaMember: [{ required: true, message: '请输入所在大区负责人', trigger: 'change' }],
          startTime: [{ required: true, message: '请选择', trigger: 'change' }],
          endTime: [{ required: true, message: '请选择', trigger: 'change' }],
        },
      },
    }
  },
  computed: {
    ...mapGetters(["stickyList"])
  },
  created() {},
  methods: {
    moment,
    initialDeliver3(record) {
      this.deliver3.form = Object.assign({}, record);
      this.deliver3.loading = false
      this.deliver3.visible = true
    },
    handleDeliver3() {
      this.$refs.form3.validate((result) => {
        console.log(result)
        if (result) {
          this.deliver3.loading = true;
          this.deliver3.form.activityDate = this.deliver3.form.startTime.substring(0, 10);
          this.deliver3.form.status = 2;
          putActivityApply(this.deliver3.form).then(_result => {
            this.$message.success('操作成功')
            this.$refs.table.refresh(true)
            this.deliver3.visible = false
            this.deliver3.loading = false;
          }).catch(() => {
            this.deliver3.loading = false;
          })
        }
      })
    },
    async handleUpload2(event, key) {
      this.loading = true
      let result = await client.put('avatar/' + event.file.uid, event.file)
      this.$set(this.deliver2.form, key, result.url)
      this.loading = false
      this.$refs.form2.clearValidate()
    },
    initialDeliver2(record) {
      this.deliver2.form = Object.assign({}, record);
      this.deliver2.loading = false
      this.deliver2.visible = true
    },
    handleDeliver2() {
      this.$refs.form2.validate((result) => {
        console.log(result)
        if (result) {
          this.deliver2.loading = true;
          putActivityApply(this.deliver2.form).then(_result => {
            this.$message.success('操作成功')
            this.$refs.table.refresh(true)
            this.deliver2.visible = false
            this.deliver2.loading = false;
          }).catch(() => {
            this.deliver2.loading = false;
          })
        }
      })
    },
    initialDeliver1(record) {
      this.handleSearch1()
      this.handleSearch2()
      if (record) {
        this.deliver1.form = {
          title: record.title,
          sticky: record.sticky,
          activityCity: record.activityCity,
          startDate: record.startTime,
          endDate: record.endTime,
        };
      } else {
        this.deliver1.form = {};
      }
      this.deliver1.loading = false
      this.deliver1.visible = true
    },
    handleDeliver1() {
      this.$refs.form1.validate((result) => {
        console.log(result)
        if (result) {
          this.deliver1.loading = true;
          putActivity(this.deliver1.form).then(_result => {
            this.$message.success('操作成功')
            this.$refs.table.refresh(true)
            this.deliver1.visible = false
            this.deliver1.loading = false;
          }).catch(() => {
            this.deliver1.loading = false;
          })
        }
      })
    },
    async handleUpload(event, key) {
      this.loading = true
      let result = await client.put('activity/' + event.file.uid, event.file)
      this.$set(this.deliver1.form, key, result.url)
      this.loading = false
      this.$refs.form1.clearValidate()
    },
    handleChange1(e) {
      this.$set(this.deliver1.form, "activityMasterMember", e)
    },
    handleSearch1(keyword) {
      queryMerchant({ current: 1, size: 10, type: "president", keyword: keyword }).then(result => {
        this.presidentMerchantList = result.records;
        if (this.deliver1.form.activityMasterMember && this.deliver1.form.activityMasterMemberName && this.presidentMerchantList.findIndex(item => item.id == this.deliver1.form.activityMasterMember) < 0) {
          this.presidentMerchantList.unshift({
            id: this.deliver1.form.activityMasterMember,
            masterName: this.deliver1.form.activityMasterMemberName,
          })
        }
      })
    },
    handleChange2(e) {
      this.$set(this.deliver1.form, "activityPermissionMember", e)
    },
    handleSearch2(keyword) {
      queryMerchant({ current: 1, size: 10, keyword: keyword  }).then(result => {
        this.merchantList = result.records;
        if (this.deliver1.form.activityPermissionMember && this.deliver1.form.activityPermissionMemberName && this.merchantList.findIndex(item => item.id == this.deliver1.form.activityMasterMember) < 0) {
          this.merchantList.unshift({
            id: this.deliver1.form.activityPermissionMember,
            masterName: this.deliver1.form.activityPermissionMemberName,
          })
        }
      })
    },
    handleReset() {
      this.form.month = moment().format(monthFormatType);
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleSubmit() {
      this.$refs.table.refresh(true)
    },
    handleDelete(record) {
      deleteActivityApply({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
  }
}
</script>
<style lang="less" scoped>
</style>
